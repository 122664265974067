import {useLocale} from '@/hooks/useLocale';
import {Trans, t} from '@lingui/macro';
import {CheckoutOrderDetails, CurrencyCode, formatAmount} from '@zentact/common';

const defaultImageUrl =
  'https://storage.googleapis.com/zentact-dev-public-assets/product-placeholder3.jpg';

const getInvoiceTranslation = (invoiceNumber: string) => {
  return t`Invoice #${invoiceNumber}`;
};

type Props = {
  brandConfiguration: {
    logoUrl?: string | null;
    displayName: string;
  };
  orderDetails: CheckoutOrderDetails | null;
  total: number;
  invoiceNumber?: string;
  currency: CurrencyCode;
  showCheckoutPageInFullScreen: boolean;
};
export const CheckoutOrderDetailsSection = ({
  orderDetails: orderDetailsProp,
  total,
  brandConfiguration,
  invoiceNumber,
  currency,
  showCheckoutPageInFullScreen,
}: Props) => {
  const {locale} = useLocale();

  let orderDetails: CheckoutOrderDetails | null;
  let isInvoiceInsideDetails = false;
  if (orderDetailsProp) {
    orderDetails = orderDetailsProp;
  } else {
    orderDetails = invoiceNumber
      ? {
          lineItems: [
            {
              description: getInvoiceTranslation(invoiceNumber),
              quantity: 1,
              amount: total,
            },
          ],
          subTotal: total,
        }
      : null;
    isInvoiceInsideDetails = true;
  }

  if (!orderDetails) {
    // show only logo
    return (
      <div className="bg-gray-100 py-7">
        {brandConfiguration?.logoUrl ? (
          <img
            className="w-auto h-10 mx-auto"
            src={brandConfiguration.logoUrl}
            alt={brandConfiguration.displayName}
          />
        ) : (
          <h2 className="text-2xl leading-9 tracking-tight text-center text-gray-600">
            {brandConfiguration.displayName}
          </h2>
        )}
      </div>
    );
  }

  const formattedSubtotal = formatAmount(orderDetails.subTotal, locale, currency);
  const formattedTax = orderDetails.taxAmount
    ? formatAmount(orderDetails.taxAmount, locale, currency)
    : null;

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = defaultImageUrl;
  };

  return (
    <div
      className={`px-8 pt-10 pb-10 bg-gray-100 ${
        !showCheckoutPageInFullScreen && 'md:px-7 md:w-1/2'
      }`}
    >
      <div className={`mb-9 mt-9 ${!showCheckoutPageInFullScreen && 'md:mb-16 md:mt-32'}`}>
        {brandConfiguration?.logoUrl ? (
          <img
            className="w-auto h-10 mx-auto"
            src={brandConfiguration.logoUrl}
            alt={brandConfiguration.displayName}
          />
        ) : (
          <h2 className="text-2xl leading-9 tracking-tight text-center text-gray-600">
            {brandConfiguration.displayName}
          </h2>
        )}
      </div>
      <div className={`${!showCheckoutPageInFullScreen && 'md:max-w-[600px]'} mx-auto`}>
        <div className="flex flex-col text-gray-600 gap-y-5">
          {!!invoiceNumber && !isInvoiceInsideDetails && (
            <>
              <div className="flex items-center justify-between">
                <div className="overflow-hidden break-words md:text-2xl">
                  {getInvoiceTranslation(invoiceNumber)}
                </div>
              </div>
              <hr className="h-[2px] bg-gray-600 border-0" />
            </>
          )}
          {orderDetails.lineItems.map((order, i) => (
            <div key={`order-${i}`} className="grid items-center justify-between grid-cols-5 gap-5">
              <div className="flex items-center col-span-4">
                <img
                  className="w-[48px] h-[48px] p-1 bg-white rounded-lg shadow mr-3"
                  src={order.imageUrl ?? defaultImageUrl}
                  onError={handleImageError}
                  alt=""
                />
                <div className="flex items-center justify-between w-full overflow-hidden">
                  <div className="overflow-hidden font-semibold break-words md:text-xl">
                    {order.description}
                  </div>
                  {!isInvoiceInsideDetails && (
                    <div className="ml-5 text-right text-gray-600 md:text-xl">{order.quantity}</div>
                  )}
                </div>
              </div>
              <div className="items-center col-span-1 font-semibold text-right md:text-lg">
                {formatAmount(order.amount * order.quantity, locale, currency)}
              </div>
            </div>
          ))}
          <hr className="h-[2px] mb-7 bg-gray-600 border-0" />
        </div>
        <div className="flex md:justify-end">
          <div className="flex w-full flex-col md:text-lg gap-y-4 md:w-[220px]">
            {!!formattedTax && (
              <>
                <div className="flex justify-between">
                  <div className="text-gray-600">
                    <Trans>Subtotal</Trans>
                  </div>
                  <div className="font-semibold text-gray-600">{formattedSubtotal}</div>
                </div>
                <div className="flex justify-between">
                  <div className="text-gray-600">
                    <Trans>Tax</Trans>
                  </div>
                  <div className="font-semibold text-gray-600">{formattedTax}</div>
                </div>
                <hr className="h-[1px] bg-gray-600 border-0" />
              </>
            )}
            <div className="flex justify-between">
              <div className="text-gray-600">
                <Trans>Total</Trans>
              </div>
              <div className="font-semibold text-gray-600">
                {formatAmount(total, locale, currency)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
