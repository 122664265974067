import {z} from 'zod';

export const featuresSchema = z.object({
  disableAddingMerchantInPortal: z.boolean().optional(),
  disableOnboardingAgreement: z.boolean().optional(),
  disableMerchantInviteInAdmin: z.boolean().optional(),
  disablePortalPayments: z.boolean().optional(),
  disablePortalRefund: z.boolean().optional(),
  showCheckoutPageInFullScreen: z.boolean().optional(),
  askForDocumentsInMerchantApplication: z
    .object({
      // file upload description texts on the merchant application page
      title: z.string().optional(),
      description: z.string().optional(),
    })
    .optional(),
  requireL2DataInPortalPayment: z.boolean().optional(),
  enableTerminals: z.boolean().optional(),
  enableSurcharges: z.boolean().optional(),
  enableInterchangeReport: z
    .object({
      // icsf details should be enabled in tenant's payment accounting reports
      showInterchangeReportPage: z.boolean().optional(),
      // for dev purposes when icsf details not enabled
      mockCreditCardFeeDetails: z.boolean().optional(),
    })
    .optional(),
  merchantReferenceIdRequired: z.boolean().optional(),
});
